export const reviewData = [
  {
    name: "Arun",
    position: "Sckilled Founder",
    review:
      "Modern problem require modern solution just like sckilled hiring process",
  },
  {
    name: "Vibha",
    position: "Student",
    review: "Unique idea with unique way of hiring process.",
  },
  {
    name: "Shivani",
    position: "Student",
    review: "I got the internship in the easiest way.",
  },
  {
    name: "Sagar",
    position: "Employee",
    review: "Unique way of hiring. Really loved this idea.",
  },
  {
    name: "Sriram",
    position: "Student",
    review:
      "Unique and easy way of hiring. No need to waste thousands of money on a single course.",
  },
  {
    name: "Devarshi",
    position: "Intern at sckilled",
    review:
      "I have had the pleasure of working with a team of highly skilled professionals who have helped me develop valuable skills and gain valuable experience in the respective field. One of the things I appreciated most about my internship was the culture at Skilled. Everyone was welcoming, friendly, and supportive, which made it easy for me to ask questions and learn from my colleagues. The team's commitment to excellence and innovation inspired me to work harder and strive for excellence in everything I did. Overall, I had a fantastic experience at Sckilled, and I am grateful for the opportunity to learn from such talented professionals.",
  },
];

export const total_language = [
  "English",
  "Hindi",
  "Bengali",
  "Gujarati",
  "Marathi",
  "Telugu",
  "Tamil",
  "Urdu",
  "Kannada",
  "Odia",
  "Malayalam",
];

export const skills = ["Acceptance testing",
"animation development",
"application support",
"asset management",
"audit",
"availability management",
"benefits management",
"bussiness administration",
"bussiness intelligences",
"bussiness process improvement",
"bussiness situation analysis",
"capacity management",
"certification scheme operation",
"change control",
"competency assessment",
"configuration management",
"consultancy",
"content authoring",
"content publishing",
"continuity management",
"contract management",
"customer service support",
"data engineering",
"Data management",
"Data modelling and design",
"data science",
"data visualisation",
"database administration",
"database management",
"demand management",
"digital forensics",
"Emerging technology mointoring",
"employee experience",
"enterprise and business architecture",
"Facailities management",
"feasilibity assessment",
"financial management",
"governance",
"Hardware design",
"high-performance computing",
"Incident management",
"information assurance",
"information management",
"information security",
"information systems coordation",
"innovation",
"investment appraisal",
"IT infrastructure",
"Knowledge management",
"learning and development management",
"learning delivery",
"learning design and development",
"Machine learning",
"marketing",
"measurement",
"methods and tools",
"Network design",
"network support",
"numerical analysis",
"Organisation design and implementation",
"organisational capability development",
"organisational facilitation",
"organisational change management",
"Penetration testing",
"performance management",
"personal data protection",
"portfolio management",
"portfolio , programme and project support",
"problem management",
"product management",
"professional development",
"programme management",
"programming/software development",
"project management",
"Quality assurance",
"quality management",
"Radio frequency engineering",
"real-time/embedded systems development",
"release and deployment",
"requriements definition and management",
"research",
"resourcing",
"risk management",
"Safety assessment",
"safety engineering",
"sales support",
"scientific modelling",
"security operations",
"selling",
"service acceptance",
"service catalouge management",
"service level management",
"software configuration",
"software deisgn",
"solution architecture",
"sourcing",
"specialist advice",
"stackholder relationship management",
"storage management",
"strategic planning",
"subject formation",
"supplier management",
"sustainability",
"system software",
"system and software life cycle engineering",
"system design",
"system development management",
"system installation and removal",
"system intergration and bulid",
"Teaching",
"technology service management",
"testing",
"threat intelligence",
"User experience design",
"user experience evaluation",
"user research",
"Vulnerability assessment",
"vulnerability research",
"Workforce planning",
"Python",
"Java",
"C and C++",
"C#",
"Javascript",
"SQL",
"PHP",
"GO ",
"Kotlin",
"MATLAB",
"R",
"Swift",
"Rust",
"Ruby",
"Dart",
"Scala",
"Accountant-management",
"accountant -private practice",
"accountant -public sector",
"accounting technician",
"accounts assistant",
"actor actuary",
"acupuncturist",
"administrative assistant or officer-courts",
"adversting account executive",
"adversting copywriter",
"advice worker",
"advocate",
"advocates cler",
"aerial and satellite installer",
"aerospace engineer",
"agricultural consultany",
"agricultural or horticultural scientist",
"air cabin crew",
"air conditioning engineer",
"air quality consultant",
"air traffic controller",
"aircraft mechanic or engineer",
"airline or airport passenger service assistant",
"ambulance care assistant",
"ambulance technician",
"anatomical pathology technologist",
"animal care assistant",
"animal technician",
"animator",
"applications developer",
"arborist",
"archaeologist",
"architect",
"architectural technologist",
"archivist",
"army-officer",
"army-soldier",
"aromatherapist",
"art therapist",
"arts administrator",
"assembler-electronics",
"assistance dog trainer",
"astronomer or astrophysicist",
"auctioneer",
"audiologist",
"automotive engineer",
"Baker or confectioner",
"bank or building society customer adviser",
"bank or building society manager",
"bar worker",
"barber",
"beauty therapist",
"BIM technician",
"biochemist",
"biologist",
"biomedical scienstist",
"biotechnologist",
"blacksmith",
"boat or ship builder",
"body piercer",
"border force officer or assistant officer",
"botanist",
"brewery worker",
"bricklayer",
"british sign language interpreter",
"broadcast assistant",
"broadcast engineer",
"broadcast journalist",
"buliders'merchant",
"building services engineer",
"building standards surveyor",
"building technician",
"bus or coach driver",
"butcher",
"Cabinet maker",
"CAD technician",
"call centre agent",
"camera operator",
"car valet",
"cardiac physiologist",
"care assistant or support worker",
"care home manager",
"careers adviser",
"cartographer",
"casino dealer",
"catering manager",
"CCTV operator",
"celiling fixer",
"cemetery worker",
"ceramic designer",
"character artist",
"charity fundraiser",
"chef or cook",
"chemical engineer",
"chemcal engineering technician",
"chemical plant process operative",
"chemist",
"childminder",
"children's holiday representative",
"chiropractor",
"choreographer",
"cinema or theatreassistant",
"cinema or theatre manger",
"civil engineer",
"civil engineering technician",
"civil service administrative assistant and officer",
"civil service administrator-fast stream",
"classroom assistant-primary or early years",
"cleaner",
"clerical or administrative assistant",
"clerk of count",
"clerk of works",
"clinical or biomedical engineer",
"clinical perfusionist",
"clinical technologist",
"cloud engineer",
"CNC machinist",
"coastguard",
"commissioning editor",
"community arts workers",
"community learning and development officer",
"community warden",
"company secretary",
"composer or songwriter",
"conference producer",
"construction manger or site manager",
"construction plant mechanic",
"control and instrument engineer",
"copy editor",
"costume designer",
"counsellor",
"countryside ranger or warden",
"craft designer",
"credit manager",
"crematorium technician",
"crime scene examiner",
"cruise ship worker",
"customer service administrator",
"cyber security analyst",
"Dance movement psychotherapist",
"dance teacher ",
"dancer",
"data analyst",
"data scientist",
"database administrator",
"delivery driver",
"demolition operative",
"dental hygienisto or therapist",
"dental nurse",
"dental technician",
"dentist",
"derrickhand",
"design engineer",
"dietetic support worker",
"dietitian",
"digital content editor",
"digital marketer",
"diplomatic service operational officer",
"disc jockey-DJ",
"dispensing optician",
"distillery worker",
"diver",
"doctor-gp",
"doctor-hospital",
"document controller",
"dog groomer",
"dog handler",
"dramatherapist",
"diller",
"drilling engineer",
"driving examiner",
"driving instructor",
"dryliner",
"dynamic positioning opeator",
"Early years practitioner",
"economic  development officer",
"economist",
"electrical or electronic engineering technician",
"electrician",
"electricity distribution worker",
"electronics engineer",
"embalmer",
"engineering operative",
"emergency call handler",
"environmental consultant",
"environmental health officer",
"environmental manager",
"estimator",
"ergonomist",
"estate or land manager",
"event manager",
"exhibition designer",
"export sales manager",
"fabric cutter",
"fabricator or plater",
"facilities manager",
"farm worker or stockperson",
"farrier",
"fashion designer",
"fashion model",
"field service technician",
"film or video editor",
"financial adviser",
"fine artist",
"firefighter",
"fish farm manager",
"fish farm worker",
"fishing vessel worker",
"fitness instructor",
"flight dispatcher",
"floor layer or carpet fitter",
"floor manager-telivision",
"florist",
"food scientist or technoligist",
"forensic computer analyst",
"forensic scientist",
"forest or wildlife manager",
"forest or wildlife ranger",
"forest worker",
"forklift truck operator",
"formworker",
"freight forwarder",
"front end developer",
"fundraising manager",
"funerial director",
"furniture desiner",
"gamekeeper",
"games designer",
"games programmer",
"games tester",
"garden centre assistant",
"gardener",
"garment or textile technoligist",
"gas heating engineer",
"gas service engineer",
"general consruction operative",
"geneticist",
"geologist",
"geophysicist",
"glass designer or marker",
"glazier",
"glazing technician",
"government intelligence officer or analyst",
"graphic designer",
"groundsperson",
"gym instructor",
"hair stylist",
"health and safety inspector",
"health and safety officer",
"health records staff",
"health service manager",
"health visiter or public health nurse",
"health and ventialiation engineer",
"herbalist",
"holiday center worker ",
"homeopath",
"hospital portner",
"hotel general manager",
"hotel porter",
"hotel receptionist",
"housekeeping manager",
"housing officer",
"human resources officer or manager ",
"hypnotherapist",
"Illustrator ",
"immigration enforcement officer",
"insurance account manager",
"insurance broker",
"insurance claimes handler",
"insurance loss adjuster ",
"insurance risk surveyor",
"insurance underwriter",
"interior designer",
"interpreter",
"investment analyst",
"investment banker ",
"investment operation administrator ",
"it helpdesk analyst",
"it support engineer",
"Jeweller - retail",
"jewellery designer",
"joiner or carpenter",
"journalist or reporter",
"judge or sheriff",
"Kitchen assistant",
"Laboratory technician",
"landbased service engineer",
"landscape architect",
"landscaper",
"learning support assistant",
"lecturer - further education",
"lecturer - higher education",
"legal secretary",
"librarian or information professional",
"library or information assistant",
"lifeguard or pool attendent",
"lightin technician",
"lightin protection engineer",
"literary agent ",
"local government adminstrative assistant or officer",
"local  government officer",
"locksmith",
"loft and cavity insulation technician ",
"logistics manager",
"lorry or lgv driver",
"Machine printer",
"make-up artist",
"management consultant ",
"manufacuring systems engineer",
"marine biologist",
"marine engineer",
"market reserch executive",
"market reserch interviewer",
"marketing manager",
"mastic asphalter",
"materials  scientist or engineer",
"maternity support worker",
"mathmaticion",
"meat process worker",
"mechnical engineer",
"mechanical engineering technician",
"medical pathologist",
"medical physicist",
"medical representative",
"medical secretary",
"memner of parliament ",
"marchent navy deck officer",
"merchant navy rating",
"metorlogist",
"meter reader",
"microbilogical",
"midwife",
"mining engineer",
"model maker",
"moter vehicle technician",
"mudlogger",
"museum assistant or visitor assistant",
"museum conservation officer",
"museum or art gallery curator",
"music promotions manager",
"music therapist",
"musical instrument technoligst ",
"musician - classical",
"musician - popular",
"Nail technician",
"nanny",
"nanotechnologist nature consrvationlist ",
"naturopath",
"naval architect",
"network engineer",
"neurophysiologist",
"nuclear engineer",
"nurse - adult",
"nurse - child ",
"nurse - district",
"nurse - general practice",
"nurse - learning disabilities",
"nurse - mental health",
"nursing support worker",
"occupational therapist",
"occupational therapy support worker",
"oceanographer",
"office manager",
"offshore medic ",
"offshore service technician",
"operating deportment practitioner",
"operational resercher",
"optical assistant",
"optical technician",
"optometrist",
"orthodontist",
"orthoptist",
"osteopath",
"outdoor activities instructor or leader",
"painter and decorator",
"paralegal",
"paramedic",
"parking worden",
"parlmentary assistant",
"patent attorney",
"patent examiner",
"pattern cutter or grader",
"personal assistant",
"personal trainer",
"pest control technician",
"pet shop assistant",
"petroleum or reservoir engineer",
"pharmacist",
"pharmacologist",
"pharmacy support worker",
"pharmacy technician",
"phlebotomist",
"photographer",
"photographer",
"photographic stylist ",
"physicist",
"physiotherapist",
"physiotherapy support worker",
"piano tuner",
"picture framer",
"picture resercher",
"pilot - airline",
"pilot - helicopter",
"plant operator",
"plasterer",
"play therapist",
"playworker or play leader",
"plumber",
"podiatrist",
"podiatry support worker",
"police officer",
"port operative",
"post office customer service consultant ",
"postie",
"pre - press operator ",
"print finisher or bookbinder",
"print production planner",
"prision officer ",
"private tutor",
"procurator fiscal",
"procurement fiscal",
"procurement administrator",
"procurement manager",
"producer - radio",
"producer - tv or film",
"product designer",
"production assistant ",
"poduction controller or manager",
"production worker",
"prohect manager",
"prop master",
"prosthetic or orthotic support worker",
"prosthetist or orthotist",
"psychologist",
"psychologist - counselling",
"psychologist  - educational",
"psychologist - forensic",
"psychologist - health",
"psychologist - occupational",
"psychologist - sports and exercise",
"psychotherrapist",
"public relations officer",
"Quality control technician",
"Radiographer - diagnostic",
"radiographer - therapeutic",
"radiography support worker",
"railway maintenance engineering technicien - distribution and plant",
"railway maintenance engineering technicien - overhead line",
"railway maintenance engineering technecian - signalling",
"railway maintenance engineering technecian - telecomes",
"railway maintenance engineering technecian - track",
"railwy station assistant",
"ramp agent",
"receptionist",
"recruitment consultant",
"recycling officer",
"recycling operative ",
"reflexologist",
"refrigeration engineer",
"refuse collector",
"register of births,deaths,marriages and civil partnerships",
"rehabilitations engineering technician",
"removels operative",
"renawable energy consultant",
"renweable energy engineer",
"reprographic assistant",
"resort representative",
"respiratory physiologist",
"restarent manager",
"retail assistant",
"retail buyer",
"retail manager",
"revenue  and customes officer",
"riding instructor",
"risk manager",
"road worker",
"roadie",
"roof sheeter and cladder",
"roofer",
"roofer - felt",
"room attendent",
"roustabout",
"rov pilot technician",
"royal air force airman or airwoman ",
"royal air force officer ",
"royal marine",
"royal marines officer",
"royal navy officer",
"royal navy rating ",
"runner",
"sales representstive ",
"scaffolder",
"science artist",
"school janitor",
"scientific or technical illustrator ",
"scottish spca animal inspector",
"sculptor",
"security officer or guard",
"security systems installer",
"seo specialist",
"set designer",
"sewing machinist ",
"sheet metal worker",
"sheriff officer or messenger - at-arms",
"shoe repairer",
"shopfitter",
"signaller",
"signwriter",
"smart meter installer",
"social worker",
"softwae engineer or developer",
"software tester",
"solicitor",
"sound technician",
"special effects technician ",
"speech and language therapy support worker",
"sport and exercise scientist ",
"sports coach or instructor ",
"sports development officer",
"sports or leisure centre assistant ",
"sports or leisure centre manager",
"sports profissional",
"sports therapist",
"stablehand or groom",
"stage manager",
"stagehand",
"statistician",
"steeplejack",
"sterile services technician",
"stockbroker",
"stonemason",
"store detective",
"storyboard artist ",
"street cleaning operative",
"structural engineer",
"structural enginerring technician",
"stunt performer",
"sub - editor - journalism",
"subsea engineer",
"supermarket assistant ",
"surgeon",
"surveyor",
"surveyor - building",
"surveyor - hydrographic",
"surveyor - land or geomatics",
"surveyor - minerals or waste management",
"surveyor - planning and development",
"surveyour - quantity",
"surveyour - rural practice",
"surveyor - valuation",
"swimming teacher",
"systems analyst",
"Tailor or dressmaker",
"tax officer",
"taxi or car driver",
"teacher - additional support for learning",
"teacher - primary or early years ",
"teacher - secondary school",
"teacher - secondary school - art and design",
"teacher - secondary school -biology with science",
"teacher -secondary school - business education",
"teacher - secondary school - chemistry with science",
"teacher - secondary school -computing ",
"teacher - secondary school -design and technology/technological education",
"teacher - secondary school - drama",
"teacher  - secondary school - english",
"teacher - secondary school -gaelic",
"teacher - secondary school - geography",
"teacher - secondary school - history",
"teacher - secondary school - home economics",
"teacher - secondary school - mathmatics",
"teacher - secondary school - modren foreign languages ",
"teacher -secondary school - modren studies",
"teacher - secondary school -  music",
"teacher - secondary school - physical education",
"teacher - secondary school - ",
"teacher - secondary school - religious education",
"teacher of english as a foreign language",
"technical author",
"technical brewer",
"technical distiller",
"technical surveyor",
"telecommunications engineer",
"textile designer",
"textile operative",
"theater support worker",
"thermal insulation engineer",
"toolmaker",
"toolpusher",
"tour guide",
"tour leader or manager",
"town planner",
"town planning assistant or technician",
"trade union official",
"trading standards officer",
"train conductor",
"train driver",
"train maintenance technician",
"training officer or manager",
"translator",
"travel agency manager",
"travel consultant",
"tv or film director",
"tv or exhaust fitter",
"Upholsterer",
"user experience (ux) designar",
"Vehicle body repairer",
"vehicle breakdown engineer",
"vehicle MET technician",
"vehicle parts advisor",
"vehicle salesperson",
"vehicle spray painter",
"veternary surgeon",
"visitor attraction manager",
"visitor  services adviser",
"visual merchandiser",
"Waiter or waitress",
"wall and floor tiler",
"warden or housing support officer - sheltered housing",
"wardrobe assistant - film,tv or theatre",
"warehouse operative",
"waste energy engineer",
"watch and clock repairer",
"water or waste water network operative",
"water or waste  water treatment operative",
"waterway designer",
"web developer",
"welder",
"wind turbine tecnician",
"window fitter",
"wood machinist",
"writer or author",
"there are no job profiles starting with x",
"Yoga teacher",
"Zookeeper",
"zoologist",
"Able seamen",
"accountants",
"accountants and auditors",
"actors",
"actuaries",
"acupuncturists",
"acute care nurses",
"adapted physical education specialists",
"adjustment clerks",
"administrative law judges ,adjudicators, and hearing officers",
"adminstritive services managers",
"adult literacy,remedical education and ged teachers and instructors",
"advanced practice psychiatric nurses ",
"advertising and promotions nurses",
"advartising sales agents",
"aerospace engeneering andoperations technicians ",
"aerospace engineers",
"agents and business managers of artists,performances and athelets",
"adricultural and food science technicians",
"agricultural crop frame managers",
"agricultural engineers",
"agricultural equipment operators",
"agricultural inspectors",
"agricultural sciences teachers,postsecondary",
"agricultural technicians",
"agricultural workers,all others",
"air crew members",
"air crew officers",
"air traffic controller",
"aircroft body and bonded structure repairers",
"aircraft cargo handling supervisors",
"aircraft engine specialists",
"aircroft body and bonded structure repairers",
"aircroft cargo handling supervisors",
"aircroft engine specialists",
"aircraft launch and recovery officers",
"aircraft launch and recovery specialists",
"aircraft mechanics and service technicians",
"aircroft rigging assemblers",
"aircroft structure assemblers ,precision",
"aircraft operations specialists",
"airframe structure,surface,rigging,and,systems,assemblers",
"airfrAame-and-power-plant mechanics",
"airline pilots,copilots,and flight engineers",
"allerigists and immunologists",
"ambulance drivers and attendants",
"anesthesiologist assistants",
"anesthesiologists",
"animal breeders",
"animal control workers ",
"animal scientists",
"animal trainers",
"anthropologists",
"anthropologists and archeologists",
"anthropology and archeology teachers ,postsecondary",
"appraisers and assesors of real estate",
"appraisers,real estate",
"aquaculatural managers",
"arbitrators,mediators,and conciliators",
"archeologists",
"architects,except landscape and navel",
"architectural and civil drafters",
"architectural teachers,postsecondary",
"archivists",
"area,ethnic,and cultural studies teachers,postsecondary",
"armored assault vehicle crew members",
"armored assault vehicle officers",
"art directors",
"art therapists",
"art,drama,and music teachers,postsecondary",
"artillery and missile crew members",
"artillery and related workers,all other",
"assemblers and fabricators,all other",
"assessors",
"astronomers",
"atheletes and sports competitors",
"althetic trainers",
"atmospheric and space scientis",
"atmospheric,earth,marine,and space sciences teachers,postssecondary",
"audio and vedio equipment technicians",
"audiologist",
"audiologists",
"audio-visuals collections specialists",
"auditors",
"automatic teller machine servicers",
"automotive body and related repairers",
"automotive engineering techncians",
"automotive engineers",
"automotive glass installers and repairers",
"automotive masters mechanics",
"automotive service technicians and mechanics",
"automotive speciality operators,power",
"aviation inspectors",
"avionics technicians",
"Baggage Porters and Bellhops",
"Bailiffs",
"Bakers",
"Bakers, Bread and Pastry",
"Bakers, Manufacturing",
"Barbers",
"Baristas",
"Bartenders",
"Battery Repairers",
"Bench Workers, Jewelry",
"Bicycle Repairers",
"Bill and Account Collectors",
"Billing and Posting Clerks and Machine Operators",
"Billing, Cost, and Rate Clerks",
"Billing, Posting, and Calculating Machine Operators",
"Bindery Machine Operators and Tenders",
"Bindery Machine Setters and Set-Up Operators",
"Bindery Workers",
"Biochemical Engineers",
"Biochemists",
"Biochemists and Biophysicists",
"Bio-fuels Processing Technicians",
"Bio-fuels Production Managers",
"Bio-fuels/Biodiesel Technology and Product Development Managers",
"Bioinformatics Scientists",
"Bioinformatics Technicians",
"Biological Science Teachers, Postsecondary",
"Biological Scientists, All Other",
"Biological Technicians",
"Biologists",
"Biomass Plant Technicians",
"Biomass Power Plant Managers",
"Biomedical Engineers",
"Biophysicists",
"Biostatisticians",
"Boat Builders and Shipwrights",
"Boiler Operators and Tenders, Low Pressure",
"Boilermakers",
"Bookbinders",
"Bookkeeping, Accounting, and Auditing Clerks",
"Brattice Builders",
"Brazers",
"Brickmasons and Blockmasons",
"Bridge and Lock Tenders",
"Broadcast News Analysts",
"Broadcast Technicians",
"Brokerage Clerks",
"Brownfield Redevelopment Specialists and Site Managers",
"Budget Analysts",
"Buffing and Polishing Set-Up Operators",
"Building Cleaning Workers, All Other",
"Bus and Truck Mechanics and Diesel Engine Specialists",
"Bus Drivers, School",
"Bus Drivers, Transit and Intercity",
"Business Continuity Planners",
"Business Intelligence Analysts",
"Business Operations Specialists, All Other",
"Business Teachers, Postsecondary",
"Butchers and Meat Cutters",
"Cabinetmakers and Bench Carpenters",
"Calibration and Instrumentation Technicians",
"Camera and Photographic Equipment Repairers",
"Camera Operators",
"Camera Operators, Television, Video, and Motion Picture",
"Captains, Mates, and Pilots of Water Vessels",
"Caption Writers",
"Cardiovascular Technologists and Technicians",
"Cargo and Freight Agents",
"Carpenter Assemblers and Repairers",
"Carpenters",
"Carpet Installers",
"Cartographers and Photogrammetrists",
"Cartoonists",
"Cashiers",
"Casting Machine Set-Up Operators",
"Ceiling Tile Installers",
"Cement Masons and Concrete Finishers",
"Cementing and Gluing Machine Operators and Tenders",
"Central Office and PBX Installers and Repairers",
"Central Office Operators",
"Chefs and Head Cooks",
"Chemical Engineers",
"Chemical Equipment Controllers and Operators",
"Chemical Equipment Operators and Tenders",
"Chemical Equipment Tenders",
"Chemical Plant and System Operators",
"Chemical Technicians",
"Chemistry Teachers, Postsecondary",
"Chemists",
"Chief Executives",
"Chief Sustainability Officers",
"Child Care Workers",
"Child Support, Missing Persons, and Unemployment Insurance Fraud Investigators",
"Child, Family, and School Social Workers",
"Chiropractors",
"Choreographers",
"City Planning Aides",
"Civil Drafters",
"Civil Engineering Technicians",
"Civil Engineers",
"Claims Adjusters, Examiners, and Investigators",
"Claims Examiners, Property and Casualty Insurance",
"Claims Takers, Unemployment Benefits",
"Cleaners of Vehicles and Equipment",
"Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders",
"Clergy",
"Climate Change Analysts",
"Clinical Data Managers",
"Clinical Nurse Specialists",
"Clinical Psychologists",
"Clinical Research Coordinators",
"Clinical, Counseling, and School Psychologists",
"Coaches and Scouts",
"Coating, Painting, and Spraying Machine Operators and Tenders",
"Coating, Painting, and Spraying Machine Setters and Set-Up Operators",
"Coating, Painting, and Spraying Machine Setters, Operators, and Tenders",
"Coil Winders, Tapers, and Finishers",
"Coin, Vending, and Amusement Machine Servicers and Repairers",
"Combination Machine Tool Operators and Tenders, Metal and Plastic",
"Combination Machine Tool Setters and Set-Up Operators, Metal and Plastic",
"Combined Food Preparation and Serving Workers, Including Fast Food",
"Command and Control Center Officers",
"Command and Control Center Specialists",
"Commercial and Industrial Designers",
"Commercial Divers",
"Commercial Pilots",
"Communication Equipment Mechanics, Installers, and Repairers",
"Communications Equipment Operators, All Other",
"Communications Teachers, Postsecondary",
"Community and Social Service Specialists, All Other",
"Community Health Workers",
"Compensation and Benefits Managers",
"Compensation and Benefits Managers",
"Compensation, Benefits, and Job Analysis Specialist",
"Compensation, Benefits, and Job Analysis Specialists",
"Compliance Managers",
"Compliance Officers, Except Agriculture, Construction, Health and Safety, and Transportation",
"Composers",
"Computer and Information Research Scientists",
"Computer and Information Scientists, Research",
"Computer and Information Systems Managers",
"Computer Hardware Engineers",
"Computer Network Architects",
"Computer Network Support Specialists",
"Computer Operators",
"Computer Programmer",
"Computer Programmers",
"Computer Science Teachers, Postsecondary",
"Computer Security Specialists",
"Computer Software Engineers, Applications",
"Computer Software Engineers, Systems Software",
"Computer Specialists, All Other",
"Computer Support Specialists",
"Computer Systems Analyst",
"Computer Systems Analysts",
"Computer Systems Engineers/Architects",
"Computer User Support Specialists",
"Computer, Automated Teller, and Office Machine Repairers",
"Computer-Controlled Machine Tool Operators, Metal and Plastic",
"Concierges",
"Conservation Scientists",
"Construction and Building Inspectors",
"Construction and Related Workers, All Other",
"Construction Carpenters",
"Construction Drillers",
"Construction Laborers",
"Construction Managers",
"Continuous Mining Machine Operators",
"Control and Valve Installers and Repairers, Except Mechanical Door",
"Conveyor Operators and Tenders",
"Cooks, All Other",
"Cooks, Fast Food",
"Cooks, Institution and Cafeteria",
"Cooks, Private Household",
"Cooks, Restaurant",
"Cooks, Short Order",
"Cooling and Freezing Equipment Operators and Tenders",
"Copy Writers",
"Coroners",
"Correctional Officers and Jailers",
"Correspondence Clerks",
"Cost Estimators",
"Costume Attendants",
"Counseling Psychologists",
"Counselors, All Other",
"Counter and Rental Clerks",
"Counter Attendants, Cafeteria, Food Concession, and Coffee Shop",
"Couriers and Messengers",
"Court Clerks",
"Court Reporters",
"Court, Municipal, and License Clerks",
"Craft Artists",
"Crane and Tower Operators",
"Creative Writers",
"Credit Analysts",
"Credit Authorizers",
"Credit Authorizers, Checkers, and Clerks",
"Credit Checkers",
"Criminal Investigators and Special Agents",
"Criminal Justice and Law Enforcement Teachers, Postsecondary",
"Critical Care Nurses",
"Crossing Guards",
"Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders",
"Curators",
"Custom Tailors",
"Customer Service Representatives",
"Customer Service Representatives, Utilities",
"Customs Brokers",
"305) Cutters and Trimmers, Hand",
"306) Cutting and Slicing Machine Operators and Tenders",
"307) Cutting and Slicing Machine Setters, Operators, and Tenders",
"308) Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic",
"Cytogenetic Technologists",
"Cytotechnologists",
"Dancers",
"Data Entry Keyers",
"Data Processing Equipment Repairers",
"Data Warehousing Specialists",
"Database Administrator",
"Database Administrators",
"Database Architects",
"Demonstrators and Product Promoters",
"Dental Assistants",
"Dental Hygienists",
"Dental Laboratory Technicians",
"Dentists, All Other Specialists",
"Dentists, General",
"Dermatologists",
"Derrick Operators, Oil and Gas",
"Design Printing Machine Setters and Set-Up Operators",
"Designers, All Other",
"Desktop Publishers",
"Detectives and Criminal Investigators",
"Diagnostic Medical Sonographers",
"Dietetic Technicians",
"Dietitians and Nutritionists",
"Dining Room and Cafeteria Attendants and Bartender Helpers",
"Directors- Stage, Motion Pictures, Television, and Radio",
"Directors, Religious Activities and Education",
"Directory Assistance Operators",
"Dishwashers",
"Dispatchers, Except Police, Fire, and Ambulance",
"Distance Learning Coordinators",
"Document Management Specialists",
"Door-To-Door Sales Workers, News and Street Vendors, and Related Workers",
"Dot Etchers",
"Drafters, All Other",
"Dragline Operators",
"Dredge Operators",
"Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
"Driver-Sales Workers",
"Drywall and Ceiling Tile Installers",
"Drywall Installers",
"Duplicating Machine Operators",
"Earth Drillers, Except Oil and Gas",
"Economics Teachers, Postsecondary",
"Economists",
"Editors",
"Education Administrators, All Other",
"Education Administrators, Elementary and Secondary School",
"Education Administrators, Postsecondary",
"Education Administrators, Preschool and Child Care Center--Program",
"Education Teachers, Postsecondary",
"Education, Training, and Library Workers, All Other",
"Educational Psychologists",
"Educational, Vocational, and School Counselors",
"Electric Home Appliance and Power Tool Repairers",
"Electric Meter Installers and Repairers",
"Electric Motor and Switch Assemblers and Repairers",
"Electric Motor, Power Tool, and Related Repairers",
"Electrical and Electronic Engineering Technicians",
"Electrical and Electronic Equipment Assemblers",
"Electrical and Electronic Inspectors and Testers",
"Electrical and Electronics Drafters",
"Electrical and Electronics Installers and Repairers, Transportation Equipment",
"Electrical and Electronics Repairers, Commercial and Industrial Equipment",
"Electrical and Electronics Repairers, Powerhouse, Substation, and Relay",
"Electrical Drafters",
"Electrical Engineering Technicians",
"Electrical Engineering Technologists Electrical Engineers",
"Electrical Parts Reconditioners",
"Electrical Power-Line Installers and Repairers",
"Electricians",
"Electrolytic Plating and Coating Machine Operators and Tenders, Metal and Plastic",
"Electrolytic Plating and Coating Machine Setters and Set-Up Operators, Metal and Plastic",
"Electromechanical Engineering Technologists",
"Electromechanical Equipment Assemblers",
"Electro-Mechanical Technicians",
"Electronic Drafters",
"Electronic Equipment Installers and Repairers, Motor Vehicles",
"Electronic Home Entertainment Equipment Installers and Repairers",
"Electronic Masking System Operators",
"Electronics Engineering Technicians",
"Electronics Engineering Technologists",
"Electronics Engineers, Except Computer",
"Electrotypers and Stereotypers",
"Elementary School Teachers, Except Special Education",
"Elevator Installers and Repairers",
"Eligibility Interviewers, Government Programs",
"Embalmers",
"Embossing Machine Set-Up Operators",
"Emergency Management Directors  ",
"Emergency Management Specialists",
"Emergency Medical Technicians and Paramedics",
"Employment Interviewers, Private or Public Employment Service",
"Employment, Recruitment, and Placement Specialists",
"Endoscopy Technicians Energy Auditors",
"Energy Brokers",
"Energy Engineers",
"Engine and Other Machine Assemblers",
"Engineering Managers",
"Engineering Teachers, Postsecondary",
"Engineering Technicians, Except Drafters, All Other",
"Engineers, All Other",
"English Language and Literature Teachers, Postsecondary",
"Engraver Set-Up Operators",
"Engravers, Hand",
"Engravers--Carvers",
"Entertainers and Performers, Sports and Related Workers, All Other",
"Entertainment Attendants and Related Workers, All Other",
"Environmental Compliance Inspectors",
"Environmental Economists *** New ***",
"Environmental Engineering Technicians",
"Environmental Engineers",
"Environmental Restoration Planners *** New ***",
"Environmental Science and Protection Technicians, Including Heal",
"Environmental Science Teachers, Postsecondary",
"Environmental Scientists and Specialists, Including Health",
"Epidemiologists",
"Equal Opportunity Representatives and Officers",
"Etchers",
"Etchers and Engravers",
"Etchers, Hand",
"Excavating and Loading Machine and Dragline Operators",
"Excavating and Loading Machine Operators",
"Executive Secretaries and Administrative Assistants",
"Exercise Physiologists *** New ***",
"Exhibit Designers",
"Explosives Workers, Ordnance Handling Experts, and Blasters",
"Extraction Workers, All Other",
"Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic",
"Extruding and Forming Machine Operators and Tenders, Synthetic or Glass Fibers",
"Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers",
"Extruding, Forming, Pressing, and Compacting Machine Operators and Tenders",
"Extruding, Forming, Pressing, and Compacting Machine Setters and Set-Up Operators",
"Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders",
"Fabric and Apparel Patternmakers",
"Fabric Menders, Except Garment",
"Fallers",
"Family and General Practitioners",
"Farm and Home Management Advisors",
"Farm and Ranch Managers",
"Farm Equipment Mechanics",
"Farm Labor Contractor",
"Farm Labor Contractors",
"Farm, Ranch, and Other Agricultural Managers",
"Farmers and Ranchers",
"Farmworkers and Laborers, Crop, Nursery, and Greenhouse",
"Farmworkers, Farm and Ranch Animals",
"Fashion Designers",
"Fence Erectors",
"Fiber Product Cutting Machine Setters and Set-Up Operators",
"Fiberglass Laminators and Fabricators",
"File Clerks",
"Film and Video Editors",
"Film Laboratory Technicians",
"Financial Analysts",
"Financial Examiners",
"Financial Managers",
"Financial Managers, Branch or Department",
"Financial Quantitative Analysts *** New ***",
"Financial Specialists, All Other",
"Fine Artists, Including Painters, Sculptors, and Illustrators",
"Fire Fighters",
"Fire Inspectors",
"Fire Inspectors and Investigators",
"Fire Investigators",
"Fire-Prevention and Protection Engineers",
"First-Line Supervisors and Manager-Supervisors - Agricultural Crop Workers",
"First-Line Supervisors and Manager-Supervisors - Animal Care Workers, Except Livestock",
"First-Line Supervisors and Manager-Supervisors - Animal Husbandry Workers",
"First-Line Supervisors and Manager-Supervisors - Fishery Workers",
"First-Line Supervisors and Manager-Supervisors - Horticultural Workers",
"First-Line Supervisors and Manager-Supervisors - Landscaping Workers",
"First-Line Supervisors and Manager-Supervisors - Logging Workers",
"First-Line Supervisors and Manager-Supervisors- Construction Trades Workers",
"First-Line Supervisors and Manager-Supervisors- Extractive Workers",
"First-Line Supervisors of Agricultural Crop and Horticultural Workers *** New ***",
"First-Line Supervisors of Animal Husbandry and Animal Care Workers *** New ***",
"First-Line Supervisors, Administrative Support",
"First-Line Supervisors, Customer Service",
"First-Line Supervisors-Managers of Air Crew Members",
"First-Line Supervisors-Managers of All Other Tactical Operations Specialists",
"First-Line Supervisors-Managers of Construction Trades and Extraction Workers",
"First-Line Supervisors-Managers of Correctional Officers",
"First-Line Supervisors-Managers of Farming, Fishing, and Forestry Workers",
"First-Line Supervisors-Managers of Fire Fighting and Prevention Workers",
"First-Line Supervisors-Managers of Food Preparation and Serving Workers",
"First-Line Supervisors-Managers of Helpers, Laborers, and Material Movers, Hand",
"First-Line Supervisors-Managers of Housekeeping and Janitorial Workers",
"First-Line Supervisors-Managers of Landscaping, Lawn Service, and Groundskeeping Workers",
"First-Line Supervisors-Managers of Mechanics, Installers, and Repairers",
"First-Line Supervisors-Managers of Non-Retail Sales Workers",
"First-Line Supervisors-Managers of Office and Administrative Support Workers",
"First-Line Supervisors-Managers of Personal Service Workers",
"First-Line Supervisors-Managers of Police and Detectives",
"First-Line Supervisors-Managers of Production and Operating Workers",
"First-Line Supervisors-Managers of Retail Sales Workers",
"First-Line Supervisors-Managers of Transportation and Material-Moving Machine and Vehicle Operators",
"First-Line Supervisors-Managers of Weapons Specialists--Crew Members",
"First-Line Supervisors-Managers, Protective Service Workers, All Other",
"Fish and Game Wardens",
"Fish Hatchery Managers",
"Fishers and Related Fishing Workers",
"Fitness and Wellness Coordinators *** New ***",
"Fitness Trainers and Aerobics Instructors",
"Fitters, Structural Metal- Precision",
"Flight Attendant *** New ***",
"Flight Attendants",
"Floor Layers, Except Carpet, Wood, and Hard Tiles",
"Floor Sanders and Finishers",
"Floral Designers",
"Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders",
"Food Batchmakers",
"Food Cooking Machine Operators and Tenders",
"Food Preparation and Serving Related Workers, All Other",
"Food Preparation Workers",
"Food Science Technicians",
"Food Scientists and Technologists",
"Food Servers, Nonrestaurant",
"Food Service Managers",
"Foreign Language and Literature Teachers, Postsecondary",
"Forensic Science Technicians",
"Forest and Conservation Technicians",
"Forest and Conservation Workers",
"Forest Fire Fighters",
"Forest Fire Fighting and Prevention Supervisors",
"Forest Fire Inspectors and Prevention Specialists",
"Foresters",
"Forestry and Conservation Science Teachers, Postsecondary",
"Forging Machine Setters, Operators, and Tenders, Metal and Plastic",
"Foundry Mold and Coremakers",
"Frame Wirers, Central Office",
"Fraud Examiners, Investigators and Analysts",
"Freight and Cargo Inspectors",
"Freight Forwarders",
"Freight Inspectors",
"Freight, Stock, and Material Movers, Hand",
"Fuel Cell Engineers",
"Fuel Cell Technicians",
"Fundraisers",
"Funeral Attendants",
"Funeral Directors",
"Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders",
"Furniture Finishers",
"Gaming and Sports Book Writers and Runners",
"Gaming Cage Workers",
"Gaming Change Persons and Booth Cashiers",
"Gaming Dealers",
"Gaming Managers",
"Gaming Service Workers, All Other",
"Gaming Supervisors",
"Gaming Surveillance Officers and Gaming Investigators",
"Gas Appliance Repairers",
"Gas Compressor and Gas Pumping Station Operators",
"Gas Compressor Operators",
"Gas Distribution Plant Operators",
"Gas Plant Operators",
"Gas Processing Plant Operators",
"Gas Pumping Station Operators",
"Gaugers",
"Gem and Diamond Workers",
"General and Operations Managers",
"General Farm workers",
"Genetic Counselors",
"Geneticists",
"Geodetic Surveyors",
"Geographers",
"Geographic Information Systems Technicians",
"Geography Teachers, Postsecondary",
"Geological and Petroleum Technicians",
"Geological Data Technicians",
"Geological Sample Test Technicians",
"Geologists",
"Geoscientists, Except Hydrologists and Geographers",
"Geospatial Information Scientists and Technologists",
"Geothermal Production Managers",
"Geothermal Technicians",
"Glass Blowers, Molders, Benders, and Finishers",
"Glass Cutting Machine Setters and Set-Up Operators",
"Glaziers",
"Government Property Inspectors and Investigators",
"Government Service Executives",
"Grader, Bulldozer, and Scraper Operators",
"Graders and Sorters, Agricultural Products",
"Graduate Teaching Assistants",
"Graphic Designers",
"Green Marketers",
"Grinding and Polishing Workers, Hand",
"Grinding, Honing, Lapping, and Deburring Machine Set-Up Operators",
"Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
"Grips and Set-Up Workers, Motion Picture Sets, Studios, and Stages",
"Grounds Maintenance Workers, All Other",
"Hairdressers, Hairstylists, and Cosmetologists",
"Hand and Portable Power Tool Repairers",
"Hand Compositors and Typesetters",
"Hazardous Materials Removal Workers",
"Health and Safety Engineers, Except Mining Safety Engineers and Inspectors",
"Health Diagnosing and Treating Practitioners, All Other",
"Health Educators",
"Health Specialties Teachers, Postsecondary",
"Health Technologists and Technicians, All Other",
"Healthcare Practitioners and Technical Workers, All Other",
"Healthcare Support Workers, All Other",
"Hearing Aid Specialists",
"Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic",
"Heat Treating, Annealing, and Tempering Machine Operators and Tenders, Metal and Plastic",
"Heaters, Metal and Plastic",
"Heating and Air Conditioning Mechanics",
"Heating Equipment Setters and Set-Up Operators, Metal and Plastic",
"Heating, Air Conditioning, and Refrigeration Mechanics and Installers",
"Helpers, Construction Trades, All Other",
"Helpers--Brick masons, Block masons, Stonemasons, and Tile and Marble Setters",
"Helpers--Carpenters",
"Helpers--Electricians",
"Helpers--Extraction Workers",
"Helpers--Installation, Maintenance, and Repair Workers",
"Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons",
"Helpers--Pipe layers, Plumbers, Pipefitters, and Steamfitters",
"Helpers--Production Workers",
"Helpers--Roofers",
"Highway Maintenance Workers",
"Highway Patrol Pilots",
"Historians",
"History Teachers, Postsecondary",
"Histotechnologists and Histologic Technicians *** New ***",
"Hoist and Winch Operators",
"Home Appliance Installers",
"Home Appliance Repairers",
"Home Economics Teachers, Postsecondary",
"Home Health Aides",
"Hospitalists",
"Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop",
"Hotel, Motel, and Resort Desk Clerks",
"Housekeeping Supervisors",
"Human Factors Engineers and Ergonomists",
"Human Resources Assistants, Except Payroll and Timekeeping",
"Human Resources Manager",
"Human Resources Managers",
"Human Resources Managers, All Other",
"Human Resources, Training, and Labor Relations Specialists, All Other",
"Hunters and Trappers",
"Hydroelectric Plant Technicians",
"Hydroelectric Production Managers",
"Hydrologists",
"Immigration and Customs Inspectors",
"Industrial Ecologists",
"Industrial Engineering Technicians",
"Industrial Engineering Technologists",
"Industrial Engineers",
"Industrial Machinery Mechanics",
"Industrial Production Managers",
"Industrial Safety and Health Engineers",
"Industrial Truck and Tractor Operators",
"Industrial-Organizational Psychologists",
"Infantry",
"Infantry Officers",
"Informatics Nurse Specialists",
"Information and Record Clerks, All Other",
"Information Security Analysts",
"Information Technology Project Managers",
"Inspectors, Testers, Sorters, Samplers, and Weighers",
"Installation, Maintenance, and Repair Workers, All Other",
"Instructional Coordinators",
"Instructional Designers and Technologists",
"Insulation Workers, Floor, Ceiling, and Wall",
"Insulation Workers, Mechanical",
"Insurance Adjusters, Examiners, and Investigators",
"Insurance Appraisers, Auto Damage",
"Insurance Claims and Policy Processing Clerks",
"Insurance Claims Clerks",
"Insurance Policy Processing Clerks",
"Insurance Sales Agents",
"Insurance Underwriters",
"Intelligence Analysts",
"Interior Designers",
"Internists, General",
"Interpreters and Translators",
"Interviewers, Except Eligibility and Loan",
"Investment Fund Managers",
"Investment Underwriters",
"Irradiated-Fuel Handlers",
"Janitorial Supervisors",
"Janitors and Cleaners, Except Maids and Housekeeping Cleaners",
"Jewelers",
"Jewelers and Precious Stone and Metal Workers",
"Job Printers",
"Judges, Magistrate Judges, and Magistrates",
"Judicial Law Clerks",
"Keyboard Instrument Repairers and Tuners",
"Kindergarten Teachers, Except Special Education",
"Labor Relations Specialists",
"Laborers and Freight, Stock, and Material Movers, Hand",
"Landscape Architects",
"Landscaping and Ground skeeping Workers",
"Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
"Laundry and Dry-cleaning Machine Operators and Tenders, Except Pressing",
"Laundry and Dry-Cleaning Workers",
"Law Clerks",
"Law Teachers, Postsecondary",
"Lawn Service Managers",
"Lawyers",
"Lay-Out Workers, Metal and Plastic",
"Legal Secretaries",
"Legal Support Workers, All Other",
"Legislators",
"Letterpress Setters and Set-Up Operators",
"Librarians",
"Library Assistants, Clerical",
"Library Science Teachers, Postsecondary",
"Library Technicians",
"License Clerks",
"Licensed Practical and Licensed Vocational Nurses",
"Licensing Examiners and Inspectors",
"Life Scientists, All Other",
"Life, Physical, and Social Science Technicians, All Other",
"Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers",
"Loading Machine Operators, Underground Mining",
"Loan Counselor",
"Loan Counselors",
"Loan Interviewers and Clerks",
"Loan Officers",
"Locker Room, Coatroom, and Dressing Room Attendants",
"Locksmiths and Safe Repairers",
"Locomotive Engineers",
"Locomotive Firers",
"Lodging Managers",
"Log Graders and Scalers",
"Logging Equipment Operators",
"Logging Tractor Operators",
"Logging Workers, All Other",
"Logisticians",
"Logistics Analysts",
"Logistics Engineers",
"Logistics Managers",
"Loss Prevention Managers",
"Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists",
"Machine Feeders and off bearers",
"Machinists",
"Magnetic Resonance Imaging Technologists",
"Maids and Housekeeping Cleaners",
"Mail Clerks and Mail Machine Operators, Except Postal Service",
"Mail Clerks, Except Mail Machine Operators and Postal Service",
"Mail Machine Operators, Preparation and Handling",
"Maintenance and Repair Worker",
"Maintenance and Repair Workers, General",
"Maintenance Workers, Machinery",
"Makeup Artists, Theatrical and Performance",
"Management Analysts",
"Managers, All Other",
"Manicurists and Pedicurists",
"Manufactured Building and Mobile Home Installers",
"Manufacturing Engineering Technologists",
"Manufacturing Engineers",
"Manufacturing Production Technicians",
"Mapping Technicians",
"Marine Architects",
"Marine Cargo Inspectors",
"Marine Engineers",
"Marine Engineers and Naval Architects",
"Market Research Analysts",
"Market Research Analysts and Marketing Specialists *** New ***",
"Marketing Managers",
"Marking and Identification Printing Machine Setters and Set-Up Operators",
"Marking Clerks",
"Marriage and Family Therapists",
"Massage Therapists",
"Material Moving Workers, All Other",
"Materials Engineers",
"Materials Inspectors",
"Materials Scientists",
"Mates- Ship, Boat, and Barge",
"Mathematical Science Occupations, All Other",
"Mathematical Science Teachers, Postsecondary",
"Mathematical Technicians",
"Mathematicians",
"Meat, Poultry, and Fish Cutters and Trimmers",
"Mechanical Door Repairers",
"Mechanical Drafters",
"Mechanical Engineering Technicians",
"Mechanical Engineering Technologists",
"Mechanical Engineers",
"Mechanical Inspectors",
"Mechatronics Engineers",
"Media and Communication Equipment Workers, All Other",
"Media and Communication Workers, All Other",
"Medical and Clinical Laboratory Technicians",
"Medical and Clinical Laboratory Technologists",
"Medical and Health Services Managers",
"Medical and Public Health Social Workers",
"Medical Appliance Technicians",
"Medical Assistants",
"Medical Equipment Preparers",
"Medical Equipment Repairers",
"Medical Records and Health Information Technicians",
"Medical Scientists, Except Epidemiologists",
"Medical Secretaries",
"Medical Transcriptionists",
"Meeting and Convention Planners",
"Mental Health and Substance Abuse Social Workers",
"Mental Health Counselors",
"Merchandise Displayers and Window Trimmers",
"Metal Fabricators, Structural Metal Products",
"Metal Molding, Core making, and Casting Machine Operators and Tenders",
"Metal Molding, Core making, and Casting Machine Setters and Set-Up Operators",
"Metal Workers and Plastic Workers, All Other",
"815) Metal-Refining Furnace Operators and Tenders",
"Meter Mechanics",
"Meter Readers, Utilities",
"Methane Landfill Gas Generation System Technicians",
"Methane/Landfill Gas Collection System Operators",
"Microbiologists",
"Microsystems Engineers",
"Middle School Teachers, Except Special and Vocational Education",
"Midwives Military Enlisted Tactical Operations and Air--Weapons Specialists and Crew Members, All Other",
"Military Officer Special and Tactical Operations Leaders--Managers, All Other",
"Milling and Planning Machine Setters, Operators, and Tenders, Metal and Plastic",
"Millwrights",
"Mine Cutting and Channeling Machine Operators",
"Mining and Geological Engineers, Including Mining Safety Engineers",
"Mining Machine Operators, All Other",
"Mixing and Blending Machine Setters, Operators, and Tenders",
"Mobile Heavy Equipment Mechanics, Except Engines",
"Model and Mold Makers, Jewelry",
"Model Makers, Metal and Plastic",
"Model Makers, Wood",
"Models",
"Mold Makers, Hand",
"Molders, Shapers, and Casters, Except Metal and Plastic",
"Molding and Casting Workers",
"Molding, Core making, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic",
"Molecular and Cellular Biologists",
"Morticians, Undertakers, and Funeral Directors Motion Picture Projectionists",
"Motor Vehicle Inspectors",
"Motor Vehicle Operators, All Other",
"Motorboat Mechanics",
"Motorboat Operators",
"Motorcycle Mechanics",
"Multi-Media Artists and Animators",
"Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
"Municipal Clerks",
"Municipal Fire Fighters",
"Municipal Fire Fighting and Prevention Supervisors",
"Museum Technicians and Conservators",
"Music Arrangers and Orchestrators",
"Music Composers and Arrangers",
"Music Directors",
"Music Directors and Composers",
"Music Therapists",
"Musical Instrument Repairers and Tuners",
"Musicians and Singers",
"Musicians, Instrumental",
"Nannies",
"Nanosystems Engineers",
"Nanotechnology Engineering Technicians",
"Nanotechnology Engineering Technologists",
"Natural Sciences Managers",
"Naturopathic Physicians",
"Network and Computer Systems Administrator",
"Network and Computer Systems Administrators",
"Network Systems and Data Communications Analysts",
"Neuro-diagnostic Technologists",
"Neurologists",
"Neuropsychologists and Clinical Neuropsychologists",
"New Accounts Clerks",
"Non-Destructive Testing Specialists",
"Non-electrolytic Plating and Coating Machine Operators and Tenders, Metal and Plastic",
"Non-electrolytic Plating and Coating Machine Setters and Set-Up Operators, Metal and Plastic",
"Nonfarm Animal Caretakers",
"Nuclear Engineers",
"Nuclear Equipment Operation Technicians",
"Nuclear Medicine Physicians",
"Nuclear Medicine Technologists",
"Nuclear Monitoring Technicians",
"Nuclear Power Reactor Operators",
"Nuclear Technicians",
"Numerical Control Machine Tool Operators and Tenders, Metal and Plastic",
"Numerical Tool and Process Control Programmers",
"Nurse Anesthetists",
"Nurse Midwives",
"Nurse Practitioners",
"Nursery and Greenhouse Manager",
"Nursery and Greenhouse Managers",
"Nursery Workers",
"Nursing Aides, Orderlies, and Attendants",
"Nursing Assistants",
"Nursing Instructors and Teachers, Postsecondary",
"Obstetricians and Gynecologists",
"Occupational Health and Safety Specialists",
"Occupational Health and Safety Technicians",
"Occupational Therapist Aides",
"Occupational Therapist Assistants",
"Occupational Therapists",
"Office and Administrative Support Workers, All Other",
"Office Clerks, General",
"Office Machine and Cash Register Servicers",
"Office Machine Operators, Except Computer",
"Offset Lithographic Press Setters and Set-Up Operators",
"Online Merchants",
"Operating Engineers",
"Operating Engineers and Other Construction Equipment Operators",
"Operations Research Analysts",
"Ophthalmic Laboratory Technicians",
"Ophthalmic Medical Technicians",
"Ophthalmic Medical Technologists",
"Ophthalmologists",
"Optical Instrument Assemblers",
"Opticians, Dispensing",
"Optometrists",
"Oral and Maxillofacial Surgeons",
"Order Clerks",
"Order Fillers, Wholesale and Retail Sales",
"Orderlies",
"Ordinary Seamen and Marine Oilers",
"Orthodontists",
"Orthoptists",
"Orthotists and Prosthetists",
"Outdoor Power Equipment and Other Small Engine Mechanics",
"Packaging and Filling Machine Operators and Tenders",
"Packers and Packagers, Hand",
"Painters and Illustrators",
"Painters, Construction and Maintenance",
"Painters, Transportation Equipment",
"Painting, Coating, and Decorating Workers",
"Pantograph Engravers",
"Paper Goods Machine Setters, Operators, and Tenders",
"Paperhangers",
"Paralegals and Legal Assistants",
"Park Naturalists",
"Parking Enforcement Workers",
"Parking Lot Attendants",
"Parts Salespersons",
"Paste-Up Workers",
"Pathologists",
"Patient Representatives",
"Patternmakers, Metal and Plastic",
"Patternmakers, Wood",
"Paving, Surfacing, and Tamping Equipment Operators",
"Payroll and Timekeeping Clerks",
"Pediatricians, General",
"Percussion Instrument Repairers and Tuners",
"Personal and Home Care Aides",
"Personal Care and Service Workers, All Other",
"Personal Financial Advisors",
"Personnel Recruiters",
"Pest Control Workers",
"Pesticide Handlers, Sprayers, and Applicators, Vegetation",
"Petroleum Engineers",
"Petroleum Pump System Operators",
"Petroleum Pump System Operators, Refinery Operators, and Gaugers",
"Petroleum Refinery and Control Panel Operators",
"Pewter Casters and Finishers",
"Pharmacists",
"Pharmacy Aides",
"Pharmacy Technicians",
"Philosophy and Religion Teachers, Postsecondary",
"Phlebotomists",
"Photoengravers",
"Photoengraving and Lithographing Machine Operators and Tenders",
"Photographers",
"Photographers, Scientific",
"Photographic Hand Developers",
"Photographic Process Workers",
"Photographic Process Workers and Processing Machine Operators",
"Photographic Processing Machine Operators",
"Photographic Reproduction Technicians",
"Photographic Retouchers and Restorers",
"Photonics Engineers",
"Photonics Technicians",
"Physical Medicine and Rehabilitation Physicians",
"Physical Scientists, All Other",
"Physical Therapist Aides",
"Physical Therapist Assistants",
"Physical Therapists",
"Physician Assistants",
"Physicians and Surgeons, All Other",
"Physicists",
"Physics Teachers, Postsecondary",
"Pile-Driver Operators",
"Pilots, Ship",
"Pipe Fitters",
"Pipelayers",
"Pipelaying Fitters",
"Plant and System Operators, All Other",
"Plant Scientists",
"Plasterers and Stucco Masons",
"Plastic Molding and Casting Machine Operators and Tenders",
"Plastic Molding and Casting Machine Setters and Set-Up Operators",
"Plate Finishers",
"Platemakers",
"Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic",
"Plumbers",
"Plumbers, Pipefitters, and Steamfitters",
"Podiatrists",
"Poets and Lyricists",
"Poets, Lyricists and Creative Writers",
"Police and Sheriffs Patrol Officers",
"Police Detectives",
"Police Identification and Records Officers",
"Police Patrol Officers",
"Police, Fire, and Ambulance Dispatchers",
"Political Science Teachers, Postsecondary",
"Political Scientists",
"Postal Service Clerks",
"Postal Service Mail Carriers",
"Postal Service Mail Sorters, Processors, and Processing Machine Operators",
"Postmasters and Mail Superintendents",
"Postsecondary Teachers, All Other",
"Potters",
"Pourers and Casters, Metal",
"Power Distributors and Dispatchers",
"Power Generating Plant Operators, Except Auxiliary Equipment Operators",
"Power Plant Operators",
"Precious Metal Workers",
"Precision Agriculture Technicians",
"Precision Devices Inspectors and Testers",
"Precision Dyers",
"Precision Etchers and Engravers, Hand or Machine",
"Precision Instrument and Equipment Repairers, All Other",
"Precision Lens Grinders and Polishers",
"Precision Mold and Pattern Casters, except Nonferrous Metals",
"Precision Pattern and Die Casters, Nonferrous Metals",
"Precision Printing Workers",
"Prepress Technician",
"Prepress Technicians and Workers",
"Preschool Teachers, Except Special Education",
"Press and Press Brake Machine Setters and Set-Up Operators, Metal and Plastic",
"Pressers, Delicate Fabrics",
"Pressers, Hand",
"Pressers, Textile, Garment, and Related Materials",
"Pressing Machine Operators and Tenders- Textile, Garment, and Related Materials",
"Pressure Vessel Inspectors",
"Preventive Medicine Physicians",
"Print Binding and Finishing Workers",
"Printing Machine Operators",
"Printing Press Machine Operators and Tenders",
"Printing Press Operators",
"Private Detectives and Investigators",
"Private Sector Executives",
"Probation Officers and Correctional Treatment Specialists",
"Procurement Clerks",
"Producers",
"Producers and Directors",
"Product Safety Engineers",
"Production Helpers",
"Production Inspectors, Testers, Graders, Sorters, Samplers, Weighers",
"Production Laborers",
"Production Workers, All Other",
"Production, Planning, and Expediting Clerks",
"Professional Photographers",
"Program Directors",
"Proofreaders and Copy Markers",
"Property, Real Estate, and Community Association Managers",
"Prosthodontists",
"Protective Service Workers, All Other",
"Psychiatric Aides",
"Psychiatric Technicians",
"Psychiatrists",
"Psychologists, All Other",
"Psychology Teachers, Postsecondary",
"Public Address System and Other Announcers",
"Public Relations Managers",
"Public Relations Specialists",
"Public Transportation Inspectors",
"Pump Operators, Except Wellhead Pumpers",
"Punching Machine Setters and Set-Up Operators, Metal and Plastic",
"Purchasing Agents and Buyers, Farm Products",
"Purchasing Agents, Except Wholesale, Retail, and Farm Products",
"Purchasing Managers",
"Quality Control Analysts",
"Quality Control Systems Managers",
"Radar and Sonar Technicians",
"Radiation Therapists",
"Radio and Television Announcers",
"Radio Frequency Identification Device Specialists",
"Radio Mechanic",
"Radio Mechanic",
"Radio Operators",
"Radiologic Technician",
"Radiologic Technicians",
"Radiologic Technologists",
"Radiologic Technologists and Technicians",
"Radiologists",
"Rail Car Repairers",
"Rail Transportation Workers, All Other",
"Rail Yard Engineers, Dinkey Operators, and Hostlers",
"Railroad Brake, Signal, and Switch Operators",
"Railroad Conductors and Yardmasters",
"Railroad Inspectors",
"Railroad Yard Workers",
"Rail-Track Laying and Maintenance Equipment Operators",
"Range Managers",
"Real Estate Brokers",
"Real Estate Sales Agents",
"Receptionists and Information Clerks",
"Recreation and Fitness Studies Teachers, Postsecondary",
"Recreation Workers",
"Recreational Therapists",
"Recreational Vehicle Service Technicians",
"Recycling and Reclamation Workers",
"Recycling Coordinators",
"Reed or Wind Instrument Repairers and Tuners",
"Refractory Materials Repairers, Except Brickmasons",
"Refrigeration Mechanics",
"Refuse and Recyclable Material Collectors",
"Registered Nurses",
"Registered Nurses",
"Regulatory Affairs Managers",
"Regulatory Affairs Specialists",
"Rehabilitation Counselors",
"Reinforcing Iron and Rebar Workers",
"Religious Workers, All Other",
"Remote Sensing Scientists and Technologists",
"Remote Sensing Technicians",
"Reporters and Correspondents",
"Reservation and Transportation Ticket Agents",
"Reservation and Transportation Ticket Agents and Travel Clerks",
"Residential Advisors",
"Respiratory Therapists",
"Respiratory Therapy Technicians",
"Retail Loss Prevention Specialists",
"Retail Salespersons",
"Riggers",
"Risk Management Specialists",
"Robotics Engineers",
"Robotics Technicians",
"Rock Splitters, Quarry",
"Rolling Machine Setters, Operators, and Tenders, Metal and Plastic",
"Roof Bolters, Mining",
"Roofers",
"Rotary Drill Operators, Oil and Gas",
"Rough Carpenters",
"Roustabouts, Oil and Gas",
"Sailors and Marine Oilers",
"Sales Agents, Financial Services",
"Sales Agents, Securities and Commodities",
"Sales and Related Workers, All Other",
"Sales Engineers",
"Sales Managers",
"Sales Representatives, Agricultural",
"Sales Representatives, Chemical and Pharmaceutical",
"Sales Representatives, Electrical--Electronic",
"Sales Representatives, Instruments",
"Sales Representatives, Mechanical Equipment and Supplies",
"Sales Representatives, Medical",
"Sales Representatives, Services, All Other",
"Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products",
"Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products",
"Sawing Machine Operators and Tenders",
"Sawing Machine Setters and Set-Up Operators",
"Sawing Machine Setters, Operators, and Tenders, Wood",
"Sawing Machine Tool Setters and Set-Up Operators, Metal and Plastic",
"Scanner Operators",
"Screen Printing Machine Setters and Set-Up Operators Sculptors",
"Search Marketing Strategists",
"Secondary School Teachers, Except Special and Vocational Education",
"Secretaries, Except Legal, Medical, and Executive",
"Securities and Commodities Traders",
"Securities, Commodities, and Financial Services Sales Agents",
"Security and Fire Alarm Systems Installers",
"Security Guards",
"Security Management Specialists",
"Security Managers",
"Segmental Pavers",
"Self-Enrichment Education Teachers",
"Semiconductor Processors",
"Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders",
"Septic Tank Servicers and Sewer Pipe Cleaners",
"Service Station Attendants",
"Service Unit Operators, Oil, Gas, and Mining",
"Set and Exhibit Designers",
"Set Designers",
"Sewers, Hand",
"Sewing Machine Operators",
"Sewing Machine Operators, Garment",
"Sewing Machine Operators, Non-Garment",
"Shampooers",
"Shear and Slitter Machine Setters and Set-Up Operators, Metal and Plastic",
"Sheet Metal Workers",
"Sheriffs and Deputy Sheriffs",
"Ship and Boat Captains",
"Ship Carpenters and Joiners",
"Ship Engineers",
"Shipping, Receiving, and Traffic Clerks",
"Shoe and Leather Workers and Repairers",
"Shoe Machine Operators and Tenders",
"Shop and Alteration Tailors",
"Shuttle Car Operators",
"Signal and Track Switch Repairers",
"Silversmiths",
"Singers",
"Sketch Artists",
"Skin Care Specialists",
"Slaughterers and Meat Packers",
"Slot Key Persons",
"Social and Community Service Managers",
"Social and Human Service Assistants",
"Social Science Research Assistants",
"Social Sciences Teachers, Postsecondary, All Other",
"Social Scientists and Related Workers, All Other",
"Social Work Teachers, Postsecondary",
"Social Workers, All Other",
"Sociologists",
"Sociology Teachers, Postsecondary",
"Software Developers, Applications",
"Software Developers, Systems Software",
"Software Quality Assurance Engineers and Testers",
"Soil and Plant Scientists",
"Soil Conservationists",
"Soil Scientists",
"Solar Energy Installation Managers",
"Solar Energy Systems Engineers",
"Solar Photovoltaic Installers",
"Solar Sales Representatives and Assessors",
"Solar Thermal Installers and Technicians",
"Solderers",
"Solderers and Brazers",
"Soldering and Brazing Machine Operators and Tenders",
"Soldering and Brazing Machine Setters and Set-Up Operators",
"Sound Engineering Technicians",
"Spa Managers",
"Special Education Teacher, Secondary School",
"Special Education Teachers, Kindergarten and Elementary School",
"Special Education Teachers, Middle School",
"Special Education Teachers, Middle School",
"Special Education Teachers, Preschool",
"Special Education Teachers, Preschool, Kindergarten, and Elementary School",
"Special Education Teachers, Secondary School",
"Special Forces",
"Special Forces Officers",
"Speech-Language Pathologists",
"Speech-Language Pathology Assistants",
"Sports Medicine Physicians",
"Spotters, Dry Cleaning",
"Statement Clerks",
"Station Installers and Repairers, Telephone",
"Stationary Engineers",
"Stationary Engineers and Boiler Operators",
"Statistical Assistants",
"Statisticians",
"Stevedores, Except Equipment Operators",
"Stock Clerks and Order Fillers",
"Stock Clerks- Stockroom, Warehouse, or Storage Yard",
"Stock Clerks, Sales Floor",
"Stone Cutters and Carvers",
"Stone Sawyers",
"Stonemasons",
"Storage and Distribution Managers",
"Stringed Instrument Repairers and Tuners",
"Strippers",
"Structural Iron and Steel Workers",
"Structural Metal Fabricators and Fitters",
"Substance Abuse and Behavioral Disorder Counselors",
"Subway and Streetcar Operators",
"Supply Chain Managers",
"Surgeons",
"Surgical Assistants",
"Surgical Technologists",
"Survey Researchers",
"Surveying and Mapping Technicians",
"Surveying Technicians",
"Surveyors",
"Sustainability Specialists",
"Switchboard Operators, Including Answering Service",
"Tailors, Dressmakers, and Custom Sewers",
"Talent Directors",
"Tank Car, Truck, and Ship Loaders",
"Tapers",
"Tax Examiners, Collectors, and Revenue Agents",
"Tax Preparers",
"Taxi Drivers and Chauffeurs",
"Teacher Assistants",
"Teachers and Instructors, All Other",
"Team Assemblers",
"Technical Directors--Managers",
"Technical Writers",
"Telecommunications Engineering Specialists",
"Telecommunications Equipment Installers and Repairers, Except Line Installers",
"Telecommunications Facility Examiners",
"Telecommunications Line Installers and Repairers",
"Telemarketers",
"Telephone Operators",
"Tellers",
"Terrazzo Workers and Finishers",
"Textile Bleaching and Dyeing Machine Operators and Tenders",
"Textile Cutting Machine Setters, Operators, and Tenders",
"Textile Knitting and Weaving Machine Setters, Operators, and Tenders",
"Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders",
"Textile, Apparel, and Furnishings Workers, All Other",
"Therapists, All Other",
"Tile and Marble Setters",
"Timing Device Assemblers, Adjusters, and Calibrators",
"Tire Builders",
"Tire Repairers and Changers",
"Title Examiners and Abstractors",
"Title Examiners, Abstractors, and Searchers",
"Title Searchers",
"Tool and Die Makers",
"Tool Grinders, Filers, and Sharpeners",
"Tour Guides and Escorts",
"Tour Guides and Escorts",
"Tractor-Trailer Truck Drivers",
"Traffic Technicians",
"Train Crew Members",
"Training and Development Manager",
"Training and Development Managers",
"Training and Development Specialists",
"Training and Development Specialists",
"Transformer Repairers",
"Transit and Railroad Police",
"Transportation Attendants, Except Flight Attendants",
"Transportation Attendants, Except Flight Attendants and Baggage Porters",
"Transportation Engineers",
"Transportation Inspectors",
"Transportation Managers",
"Transportation Planners",
"Transportation Security Screeners",
"Transportation Vehicle, Equipment and Systems Inspectors, Except Aviation",
"Transportation Workers, All Other",
"Transportation, Storage, and Distribution Managers",
"Travel Agents",
"Travel Clerks",
"Travel Guide",
"Travel Guides",
"Treasurers, Controllers, and Chief Financial Officers",
"Tree Trimmers and Pruners",
"Truck Drivers, Heavy",
"Truck Drivers, Heavy and Tractor-Trailer",
"Truck Drivers, Light or Delivery Services",
"Tutors",
"Typesetting and Composing Machine Operators and Tenders",
"Umpires, Referees, and Other Sports Officials",
"Upholsterers",
"Urban and Regional Planners",
"Urologists",
"Ushers, Lobby Attendants, and Ticket Takers",
"Validation Engineers",
"Valve and Regulator Repairers",
"Veterinarians",
"Veterinary Assistants and Laboratory Animal Caretakers",
"Veterinary Technologists and Technicians",
"Video Game Designers",
"Vocational Education Teachers Postsecondary",
"Vocational Education Teachers, Middle School",
"Vocational Education Teachers, Secondary School",
"Waiters and Waitresses",
"Watch Repairers",
"Water and Liquid Waste Treatment Plant and System Operators",
"Water Resource Specialists",
"Water/Wastewater Engineers",
"Weatherization Installers and Technicians",
"Web Administrators",
"Web Developers",
"Weighers, Measurers, Checkers, and Samplers, Recordkeeping",
"Welder-Fitters",
"Welders and Cutters",
"Welders, Cutters, and Welder Fitters",
"Welders, Cutters, Solderers, and Brazers",
"Welders, Production",
"Welding Machine Operators and Tenders",
"Welding Machine Setters and Set-Up Operators",
"Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders",
"Welfare Eligibility Workers and Interviewers",
"Well and Core Drill Operators",
"Wellhead Pumpers",
"Wholesale and Retail Buyers, Except Farm Products",
"Wind Energy Engineers",
"Wind Energy Operations Managers",
"Wind Energy Project Managers",
"Wind Turbine Service Technicians",
"Woodworkers, All Other",
"Woodworking Machine Operators and Tenders, Except Sawing",
"Woodworking Machine Setters and Set-Up Operators, Except Sawing",
"Woodworking Machine Setters, Operators, and Tenders, Except Sawing",
"Word Processors and Typists",
"Writers and Authors",
"Zoologists and Wildlife Biologists",
"                                                                                                                                 programming languanges",
"Rust",
"SQL",
"Perl",
"objective-c",
"R ",
"MATLAB",
"Haskell",
"Lua",
"Dart",
"Java",
"C++",
"Javascript",
"PHP",
"Ruby",
"Kotlin",
"Go",
"C#",
"Typescript",
"Swift",
"Scala",
"Groovy",
"Erlang",
"Clojure",
"F#",
"Scheme",
"Smalltalk",
"Prolog",
"Fortran",
"Pascal",
"Ada",
"COBOL",
"Lisp",
"Assembly language",
"Scratch",
"Logo",
"BASIC",
"ActionScript",
"Tcl",
"Awk",
"Sed",
"PowerShell",
"Crystal",
"Julia",
"CoffeeScript",
"Elixir",
"Elm",
"Objective-j",
"                                                                                                                 marketing tools",
"Google analytics",
"Hubspot",
"Mailchimp",
"Hootsuite",
"Buffer",
"Ahrefs",
"SEMrush",
"Moz Pro",
"Yoast SEO",
"Google AdWords",
"Facebook Ads Manager",
"Linkedln Ads",
"Twitter Ads",
"Hotjar",
"Crazy Egg",
"Buzzsumo",
"Trello",
"Asana",
"Canava",
"Piktochart",
"Sprout Social",
"Brandwatch",
"mention",
"SumoMe",
"OptinMonster",
"Hello Bar",
"Leadpages",
"Unbounce",
"CrazyCall",
"Agorapulse",
"electrical engineering tools",
"Multimeter",
"Oscilloscope",
"Function Generator",
"Power Supply",
"Logic Analyzer",
"Spectrum Analyzer",
"Signal Generator",
"Digital Stroage Oscilloscope",
"LCR Meter",
"Network Analyzer",
"Electronic Load",
"Current Probe",
"Voltage probe",
"Clamp Meter",
"Power Analyzer",
"Impedance Analyzer",
"Frequency Counter",
"Audio Analyzer",
"Thermal Camera",
"Infrared Thermometer",
"Soldering Iron",
"Desoldering Station",
"Wire Strippers",
"Wire Cutters",
"Crimping Tool",
"Heat Gun",
"Wire Ferrules",
"Heat Shrink Tubing",
"Electrical Tape",
"Wire Connectors",
"Civil engineering tools",
"Total Station",
"Theodolite",
"GPS Receiver",
"Laser Level",
"Automatic Level",
"Measuring Wheel",
"Distance Measuring tool",
"Plumb Bob",
"Engineer's Scale",
"Taping Rod",
"Steel Tape Measure",
"Concrete Test Hammer",
"Rebar Locator",
"Ultrasonic Testing Equipment",
"Ground Penetrating Radar",
"Soil Sampling Equipment",
"Inclinometer",
"Pile Driving Analyzer",
"Plate Load Test Apparatus",
"Geotechnical software",
"Structural Analysis Software",
"GIS software",
"CAD Software",
"3D Printing Technology",
"Auger",
"Soil Stabilization Equipment",
"Concrete Vibrator",
"Compaction Equipment",
"Trench Shoring System",
"Concrete Mixers",
"Mechanical engineering tools",
"Calipers",
"Micrometers",
"Vernier Gauge",
"Height Gauge",
"Dial Indicator",
"Feeler Gauge",
"Bore Gauge",
"Surface Plate",
"Angle Plate",
"V-Blocks",
"Center Punch",
"Scriber",
"Allen Keys",
"Socket Set",
"Wrenches",
"Pliers",
"Screwdrivers",
"Hammer",
"Hacksaw",
"Power Drill",
"Lathe",
"Milling Machine",
"Shaper",
"Grinder",
"Welding Machine",
"Plasma Cutter",
"Shears",
"Hydraulic Press",
"Belt Sander"
];

export const services = [
  "Banking",
  "Insurance",
  "Transportation",
  "Warehousing",
  "Communication",
  "Telecom service",
  "Construction",
  "Legal",
  "Finance",
  "Marketing",
  "Real Estate",
  "Printing",
  "Landscaping",
  "Maintenance",
  "Education",
  "Tech Support",
  "Utilities",
  "Management",
  "Consulting",
  "Software",
  "Security",
  "Health and wellness",
  "Training",
  "Design",
  "Travel",
  "Delivery",
  "Childcare",
  "Photo and videography",
  "Engineering",
  "Others",
];

export const interest = [
  "fostering animals",
  "volunteering within your religious organization",
  "firefighter/EMT",
  "working for local committees ",
  "caoching youth sports teams",
  "volunteering with organizations that help the homeless",
  "volunteering at an animal shelter",
  "tutoring",
  "volunteering at local meetups or hackathons",
  "running",
  "yoga",
  "weight lifting",
  "dance",
  "golf",
  "karate",
  "pilates",
  "basketball",
  "volleyball",
  "bowling",
  "rock climbing",
  "biking",
  "skiing/snowboarding",
  "swimming",
  "hiking",
  "photography",
  "painting",
  "drawing",
  "comic books",
  "sewing",
  "classic films",
  "instruments",
  "interior decorating",
  "writing",
  "calligraphy",
  "stand-up comedy",
  "sining",
  "rapping",
  "chess",
  "poker",
  "dungeons and dragons",
  "sudoku",
  "jigsaw puzzles",
  "crossword puzzles",
  "trivia",
  "learning languages",
  "travelling",
  "foodie",
  "gardening",
  "brewing beer",
  "cooking",
  "baking",
  "BBQing",
  "social media",
  "camping",
];

export const experience = ["6 month","1 Year","2 Years","3 Years","4 Years", "5 Years","6 Years","7 Years","8 Years","9 Years","10+ Years"]
